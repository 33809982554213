<template>
  <div class="q-pa-xl row justify-center">
    <div class="text-h4">You don't see anything wrong, but I just sent an error alert to the LMS team.</div>
  </div>
</template>

<script>
export default {
  created () {
    try {
      this.fake()
    } catch (e) {
      throw e
    }
  }
}
</script>
